/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { SiteMetaQuery } from '../graphql';
import logo from '../../content/assets/logo.png';

type MetaProps = JSX.IntrinsicElements['meta'];

interface SEOProps {
    description?: string;
    lang?: string;
    meta?: MetaProps[];
    title?: string;
}

function SEO({ description, lang = `nl`, meta = [], title }: SEOProps) {
    const { site }: SiteMetaQuery = useStaticQuery(
        graphql`
            query SiteMeta {
                site {
                    siteMetadata {
                        title
                        siteUrl
                        description
                        author {
                            email
                            name
                        }
                    }
                }
            }
        `
    );

    const metaDescription = description ?? site?.siteMetadata?.description ?? '';

    const constantMeta: MetaProps[] = [
        {
            name: `description`,
            content: metaDescription,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        { property: `og:image`, content: `${site?.siteMetadata?.siteUrl}${logo}` || undefined },
        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:creator`,
            content: 'Verkoop w Woning',
        },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:description`,
            content: metaDescription,
        },
    ];

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site?.siteMetadata?.title}`}
            meta={constantMeta.concat(meta)}
        />
    );
}

export default SEO;
