import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { getFontCss } from '../../constants/theme';

export type BodyVariant = 'huge' | 'large' | 'regular' | 'small' | 'tiny' | 'micro';

export interface BodyProps extends HTMLAttributes<HTMLParagraphElement> {
    variant?: BodyVariant;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elementType?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

export const BodyInner = styled.p<BodyProps>`
    font-weight: normal;
    ${({ variant = 'regular', theme }) => getFontCss(theme.fontSizes.body[variant])};
    white-space: pre-line;
    margin: 0;
`;

const Body: FC<BodyProps> = ({ variant = 'regular', children, elementType, ...rest }) => {
    return (
        <BodyInner as={elementType} variant={variant} {...rest}>
            {children}
        </BodyInner>
    );
};

export default Body;
