import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme, { getFontCss } from '../../constants/theme';
import { SpaceProps, space } from 'styled-system';

export type TitleVariant = 'large' | 'regular' | 'small' | 'tiny' | 'micro' | 'huge';

interface Props extends HTMLAttributes<{}>, SpaceProps {
    variant?: TitleVariant;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elementType?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

const elementMap: Record<TitleVariant, keyof JSX.IntrinsicElements> = {
    large: 'h1',
    regular: 'h2',
    small: 'h3',
    tiny: 'h4',
    micro: 'h5',
    huge: 'h1',
};

export const TitleInner = styled.h1<Props>`
    font-family: Gotham;
    margin: 0;
    padding: 0;
    font-weight: 700;
    white-space: pre-line;
    color: currentColor;
    ${({ variant = 'regular' }) => getFontCss(theme.fontSizes.title[variant])}
    strong {
        display: block;
    }
    ${space}
`;

const Title: FC<Props> = ({ variant = 'regular', children, elementType = elementMap[variant], ...rest }) => {
    return (
        <TitleInner
            mb={{ large: 4, regular: 4, huge: 5, micro: 2, tiny: 3, small: 3 }[variant]}
            as={elementType}
            variant={variant}
            {...rest}
        >
            {children}
        </TitleInner>
    );
};

export default Title;
