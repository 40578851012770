import React, { FC, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { space, layout, LayoutProps, FlexboxProps, flexbox } from 'styled-system';
import { BoxProps } from './Box';

const ContentWrapper: FC<BoxProps & LayoutProps & FlexboxProps & { style?: CSSProperties }> = props => {
    return <Container {...props} />;
};

export default ContentWrapper;

const Container = styled.div`
    margin: 0 auto;
    max-width: calc(var(--max-viewport) * 1px);
    padding: 0;
    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.xl - 1}px) {
        padding: 0 2rem;
        ${space}
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        padding: 0 1.6rem;
        ${space}
    }
    height: 100%;
    ${space}
    ${layout}
    ${flexbox}
`;
