/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import React, { AnchorHTMLAttributes, ComponentType, FC } from 'react';
import styled from 'styled-components/macro';
import { useContactFormDispatch } from '../context/contactform';
import Body from './atoms/Body';
import Box from './atoms/Box';
import Chat from './atoms/Chat';
import ContentWrapper from './atoms/ContentWrapper';
import Divider from './atoms/Divider';
import Flex from './atoms/Flex';
import Mail from './atoms/Mail';
import Phone from './atoms/Phone';

const Footer: FC = () => {
    const { onOpen } = useContactFormDispatch();
    return (
        <>
            <Container>
                <ContentWrapper>
                    <Box mt={5}>
                        <SubBody variant="small">Neem contact met ons op</SubBody>
                    </Box>
                    <Contacts flexDirection={['column', null, 'row']} mt={3} justifyContent="space-between">
                        <Contact
                            Component={Mail}
                            subText="Antwoord binnen 2 werkdagen"
                            href="mailto:info@verkoopuwwoning.nl"
                        >
                            Stuur ons een e-mail
                        </Contact>
                        <Contact onClick={onOpen} Component={Chat} subText="Maandag-Vrijdag, 9:00-18:00">
                            Vul het contactformulier in
                        </Contact>
                        <Contact href="tel:+31202101070" Component={Phone} subText="Maandag-Vrijdag, 9:00-18:00">
                            Bel ons
                        </Contact>
                    </Contacts>
                    <Divider my={5} />

                    <Flex mt={5}>
                        <Box>
                            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                            <SubBody variant="small">{`Pagina's`}</SubBody>
                            <Flex mt={3}>
                                <Link to="/">Home</Link> &nbsp;|&nbsp; <Link to="/over-ons">Over ons</Link>&nbsp;
                                |&nbsp;
                                <Link to="/#hoe-werkt-het">Hoe werkt het?</Link> &nbsp;| &nbsp;
                                <Link to="/contact">Contact</Link>
                            </Flex>
                        </Box>
                    </Flex>

                    <Divider mt={5} />
                    {/* <Box mb={3}>
                        <SubBody variant="small">Volg ons op</SubBody>
                    </Box> */}
                    {/* <Socials variant="inline" spacing={4}> */}
                    {/* <Facebook /> */}
                    {/* <Linkedin /> */}
                    {/* <Twitter /> */}
                    {/* <a href="https://www.instagram.com/verkoopuwwoning/" target="_blank" rel="noreferrer">
                            <Instagram />
                        </a>
                    </Socials>
                    <Divider my={5} /> */}
                    <Flex justifyContent="space-between" flexDirection={['column', null, null, null, 'row']}>
                        <Box>
                            <Body>© Verkoop uw woning | {new Date().getFullYear()} </Body>
                        </Box>
                        <Box>
                            <Body>
                                <Link to="/">Privacyverklaring</Link> | <Link to="/">Algemene voorwaarden</Link>
                            </Body>
                        </Box>
                    </Flex>
                </ContentWrapper>
            </Container>
        </>
    );
};

export default Footer;

const Contacts = styled(Flex)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        > * + * {
            margin-top: 1.6rem;
        }
    }
`;

// const Socials = styled(Stack)``;

interface ContactProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    Component: ComponentType;
    subText: string;
}
const Contact: FC<ContactProps> = ({ Component, children, subText, ...rest }) => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        <ContactWrapper as="a" {...rest}>
            <Component />
            <Box>
                <Body>
                    <strong>{children}</strong>
                </Body>
                <SubBody variant="tiny">{subText}</SubBody>
            </Box>
        </ContactWrapper>
    );
};

const SubBody = styled(Body)`
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral[4]};
`;

const ContactWrapper = styled(Flex)`
    /* align-items: center; */
    cursor: pointer;
    text-decoration: none;
    color: inherit;

    > * + * {
        margin-left: 0.8rem;
    }
`;

const Container = styled.div`
    svg {
        width: 24px;
        height: 24px;
    }
    a {
        color: inherit;
    }
    width: 100%;
    color: white;
    background: ${({ theme }) => theme.colors.primary[5]};
    padding: 3.2rem 0;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        padding: 5rem 0 3.2rem;
        svg {
            width: 32px;
        }
    }
`;
