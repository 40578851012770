import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';

export type BoxProps = SpaceProps & LayoutProps & FlexboxProps & HTMLAttributes<HTMLDivElement>;

const Flex = styled.div<BoxProps>`
    display: flex;
    ${layout}
    ${space}
    ${flexbox}
`;
export default Flex;
