import React, { FC } from 'react';
import Flex, { BoxProps } from './Flex';
import styled, { css } from 'styled-components';
import Body from './Body';

const Logo: FC<BoxProps & { disableAnimation?: boolean; onClick?: () => void }> = props => {
    return (
        <Container alignItems="center" className="logo" {...props}>
            <svg
                viewBox="0 0 230 334"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="2"
                width="40"
                style={{ marginRight: '0.8rem' }}
            >
                <path
                    d="M115.515 6.958c42.074 4.35 75.861 21.824 96.373 60.186 4.65 8.7 8.55 18.224 10.575 27.824 1.875 8.925 1.612 18.525.937 27.712-1.237 16.837-5.437 33.224-13.087 48.299-10.05 19.799-20.812 39.261-33.187 57.823-16.537 24.75-30.899 50.736-43.761 77.586-2.588 5.437-6.037 10.724-10.05 15.224-5.812 6.525-13.049 6.15-19.387.075-7.725-7.387-12.075-16.912-16.574-26.324-12.788-26.737-27.937-52.161-43.574-77.323-12.075-19.424-23.925-39.149-31.574-60.861-6.638-18.824-9.75-38.286-7.425-58.198 1.275-10.875 5.775-20.962 10.837-30.637C32.83 35.495 60.617 17.045 96.316 9.658c6.337-1.35 12.787-1.838 19.199-2.7zm81.373 110.097c-.375-44.699-36.261-80.698-80.285-80.435-46.424.3-81.861 38.661-80.398 83.098 1.387 42.486 36.411 78.185 81.523 77.848 43.536-.338 79.498-36.937 79.16-80.511z"
                    fill="currentColor"
                    fillRule="nonzero"
                />
                <path
                    d="M116.128 63.434C101.845 74.587 88.54 84.84 75.43 95.249c-1.878 1.487-4.305 3.913-4.344 5.948-.352 16.24-.195 32.52-.195 48.994h31.971c0-7.083.509-13.422-.117-19.683-1.057-10.449 5.674-11.858 13.227-11.818 7.278.039 14.557.704 13.618 11.426-.587 6.536-.118 13.188-.118 20.154h32.089c0-16.319.196-31.933-.195-47.507-.079-2.466-2.114-5.557-4.149-7.201-13.344-10.722-26.923-21.132-41.089-32.128z"
                    fill="currentColor"
                    fillRule="nonzero"
                />
            </svg>
            <Flex flexDirection="column" style={{ textAlign: 'center' }}>
                <Body elementType="span" style={{ lineHeight: '1em' }}>
                    VERKOOP UW
                    <br />
                    <strong> WONING</strong>
                </Body>
            </Flex>
        </Container>
    );
};

export default Logo;

const Container = styled(Flex)<{ disableAnimation?: boolean }>`
    cursor: pointer;

    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        svg {
            width: 35px;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) and (max-width: 900px) {
        > div {
            display: none;
        }
    }

    ${({ disableAnimation }) =>
        !disableAnimation &&
        css`
            animation: fadein 1s 0.5s forwards;
            opacity: 0;
            @keyframes fadein {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }

            @keyframes fadeinText {
                from {
                    opacity: 0;
                    transform: translateX(-1rem);
                }
                to {
                    opacity: 1;
                    transform: translate(0);
                }
            }

            > div {
                opacity: 0;
                animation: fadeinText 0.5s 0.7s forwards;
            }
        `};
`;
