import React, { ComponentType, FC, HTMLAttributes, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, config as springConfig, SpringConfig, useSpring } from 'react-spring';
import { CSSProperties } from 'styled-components';
import Box from './atoms/Box';

export interface AppearProps extends HTMLAttributes<HTMLDivElement> {
    delay?: number;
    from?: CSSProperties;
    to?: CSSProperties;
    config?: SpringConfig | keyof typeof springConfig;
    as?: keyof JSX.IntrinsicElements | ComponentType;
    threshold?: number | number[];
    triggerOnce?: boolean;
    doLog?: boolean;
    disableInView?: boolean;
}

const Appear: FC<AppearProps> = ({
    children,
    delay,
    from = { opacity: 0.5, transform: 'translateY(20vh)' },
    to = { opacity: 1, transform: 'translateY(0)' },
    config = { ...springConfig.slow },
    threshold = 0.2,
    triggerOnce = true,
    doLog,
    disableInView = false,
    ...rest
}) => {
    const [triggered, setTriggered] = useState(false);

    const [ref, isInView] = useInView({ triggerOnce, threshold });

    useEffect(() => {
        if (!triggered && (isInView || disableInView)) {
            setTriggered(true);
        }
    }, [disableInView, isInView, triggered]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const props = useSpring({
        config: typeof config === 'string' ? springConfig[config] : config,
        delay,
        ...(triggered ? to : from),
    });
    return (
        <Container
            {...rest}
            forwardedAs={rest.as}
            ref={ref}
            style={{ ...rest.style, ...props, willChange: 'transform, opacity' }}
        >
            {children}
        </Container>
    );
};

export default Appear;

const Container = animated(Box);
