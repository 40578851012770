import React, { CSSProperties, forwardRef, FC, Ref, ComponentType } from 'react';
import styled, { css } from 'styled-components';
import { ConfigStyle, ResponsiveValue, system } from 'styled-system';
import Flex, { BoxProps } from './Flex';

type StackVariant = 'inline' | 'default';

const properties: Record<StackVariant, ConfigStyle['property']> = { default: 'marginTop', inline: 'marginLeft' };

export interface StackProps extends Omit<BoxProps, 'spacing'> {
    spacing?: ResponsiveValue<CSSProperties['margin']>;
    variant?: StackVariant;
    ref?: Ref<HTMLDivElement>;
    as?: keyof JSX.IntrinsicElements | ComponentType;
    style?: CSSProperties;
}

const Stack: FC<StackProps> = forwardRef(({ variant = 'default', ...props }, ref) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    <StyledStack variant={variant} {...props} ref={ref} />
));

const getCss = (variant: StackVariant = 'default') => css`
    flex-direction: ${variant === 'default' ? 'column' : 'row'};
    && {
        > * + * {
            ${system({ spacing: { scale: 'space', property: properties[variant] } })};
        }
    }
`;

const StyledStack = styled(Flex)<{ variant: StackVariant }>`
    ${({ variant }) => getCss(variant)}
`;

export default Stack;
