import { Formik, FormikHandlers, useFormikContext } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Sesame } from '../hooks/useSesame';
import Appear from './Appear';
import Body from './atoms/Body';
import Button from './atoms/Button';
import ContentWrapper from './atoms/ContentWrapper';
import Flex from './atoms/Flex';
import Input from './atoms/Input';
import Stack from './atoms/Stack';
import Title from './atoms/Title';
import Popover, { PopoverCloseButton } from './Popover';

export const detailsValidation = Yup.object().shape({
    name: Yup.string().required('Vul een naam in'),
    email: Yup.string()
        .email('Vul een geldig email in')
        .required('Vul een email in'),
    phone: Yup.number().required('Vul een telefoonnummer in'),
});

const ContactForm: FC<Pick<Sesame, 'onClose' | 'open'>> = ({ open, onClose }) => {
    return (
        <StyledPopover open={open}>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    comment: '',
                }}
                onSubmit={async (values, { setSubmitting, setFieldError, resetForm }) => {
                    try {
                        const formData = new FormData();
                        formData.append('form-name', 'contactformulier');

                        Object.entries(values).forEach(([key, value]) => formData.append(key, value));
                        await fetch('/?no-cache=1', {
                            method: 'POST',
                            body: formData,
                        });

                        alert('Verstuurd!');
                        onClose();
                        setTimeout(() => resetForm(), 200);
                    } catch (error) {
                        setFieldError('name', 'Er is iets misgegaan. Probeer het later opnieuw.');
                    }

                    setSubmitting(false);
                }}
                validationSchema={detailsValidation}
                validateOnMount
            >
                {({ resetForm, dirty, handleSubmit, handleReset }) => (
                    <ContentWrapper>
                        <PopoverCloseButton
                            onClick={() => {
                                if (dirty && window.confirm('Weet u zeker dat u het formulier wilt sluiten?')) {
                                    onClose();
                                    setTimeout(() => {
                                        resetForm();
                                    }, 200);
                                }
                                onClose();
                            }}
                        />
                        <ContactFormContent resetHandler={handleReset} submitHandler={handleSubmit} />
                    </ContentWrapper>
                )}
            </Formik>
        </StyledPopover>
    );
};

export const ContactFormContent = ({
    submitHandler,
    resetHandler,
}: {
    submitHandler: FormikHandlers['handleSubmit'];
    resetHandler: FormikHandlers['handleReset'];
}) => (
    <Flex width={1} height="100%" justifyContent="center" flexDirection="column" pt={['50px', null, 0]}>
        <form
            name="contact"
            onSubmit={submitHandler}
            onReset={resetHandler}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
        >
            <Wrapper spacing={4}>
                <Appear disableInView delay={250} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                    <Title style={{ color: 'currentColor' }} variant="large" elementType="h2">
                        Contact opnemen?
                    </Title>
                </Appear>
                <Appear disableInView from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                    <Input name="name" placeholder="Naam" />
                </Appear>
                <Appear disableInView delay={200} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                    <Input name="email" placeholder="Email" />
                </Appear>
                <Appear disableInView delay={400} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                    <Input name="phone" placeholder="Telefoonnummer" />
                </Appear>
                <Appear disableInView delay={600} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                    <Input
                        as="textarea"
                        name="comment"
                        placeholder="Opmerkingen"
                        style={{ resize: 'vertical', minHeight: '10rem' }}
                    />
                </Appear>
                <ErrorMessage />
                <Appear disableInView delay={1000} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                    <Button type="submit" marginTop={6}>
                        Versturen
                    </Button>
                </Appear>
            </Wrapper>
        </form>
    </Flex>
);

export default ContactForm;

const StyledPopover = styled(Popover)`
    background: #db6588;
    color: white;
`;

const Wrapper = styled(Stack)`
    color: currentColor;
`;

const ErrorMessage = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { errors, touched } = useFormikContext<any>();

    const error = Object.entries(errors).find(([key]) => touched[key])?.[1];
    if (!error) {
        return null;
    }
    return <Body>{error}</Body>;
};
