import { useEffect } from 'react';

const useScrollLock = (open: boolean) => {
    useEffect(() => {
        const { scrollY } = window;

        if (open) {
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollY}px`;
            document.body.style.left = '0';
            document.body.style.right = '0';
        } else {
            document.body.style.position = '';
            window.scrollTo(0, parseInt(document.body.style.top || '0', 10) * -1);
            document.body.style.top = '';
        }
    }, [open]);
};

export default useScrollLock;
