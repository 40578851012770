import React, { FC, InputHTMLAttributes, ComponentType } from 'react';
import { getFontCss } from '../../constants/theme';
import { LayoutProps, layout } from 'styled-system';
import { useField } from 'formik';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import styled, { css } from 'styled-components';

const Input: FC<Omit<InputHTMLAttributes<HTMLInputElement>, 'width'> &
    LayoutProps & { name: string; as?: keyof JSX.IntrinsicElements | ComponentType; isCurrency?: boolean }> = ({
    isCurrency,
    ...props
}) => {
    const [field] = useField(props.name);
    if (isCurrency) {
        return (
            <StyledCurrencyInput
                prefix="€"
                thousandSeparator
                fixedDecimalScale
                decimalScale={0}
                {...field}
                {...props}
            />
        );
    }
    return <StyledInput {...field} {...props} />;
};

export default Input;

const styles = css`
    color: currentColor;
    background: none;
    border: none;
    outline: none;
    padding-bottom: 0.4rem;
    border-bottom: 2px solid currentColor;
    position: relative;
    ${({ theme }) => getFontCss(theme.fontSizes.title.regular)};
    ${layout}
    width: 100%;
`;

const StyledCurrencyInput = styled(NumberFormat)<NumberFormatProps>`
    ${styles}
`;

export const StyledInput = styled.input`
    ${styles}
`;
