import React, { ButtonHTMLAttributes, FC, HTMLAttributes } from 'react';
import { animated, config, useSpring } from 'react-spring';
import styled from 'styled-components/macro';
import useScrollLock from '../hooks/useScrollLock';
import { Sesame } from '../hooks/useSesame';
import createBorderCss from '../utils/createBorderCss';
import Close from './atoms/Close';

type Props = Pick<Sesame, 'open'> &
    HTMLAttributes<HTMLDivElement> & { variant?: 'slide' | 'scale'; overlayAttrs?: HTMLAttributes<HTMLDivElement> };

const scaler = (open: boolean) =>
    open
        ? { opacity: 1, transform: 'scale(1)', config: config.slow }
        : { opacity: 0, config: config.slow, transform: 'scale(0.8)' };

const slider = (open: boolean) =>
    open
        ? { transform: 'translateX(0%)', config: config.slow }
        : { config: config.slow, transform: 'translateX(-100%)' };

const getAnimator = (variant: 'slide' | 'scale') => {
    switch (variant) {
        case 'slide':
            return slider;
        case 'scale':
            return scaler;
        default:
            return slider;
    }
};
// TODO: Portal
const Popover: FC<Props> = ({ open, children, variant = 'scale', overlayAttrs, ...rest }) => {
    const props = useSpring({
        ...getAnimator(variant)(open),
    });
    const overlayProps = useSpring({
        ...(open ? { opacity: 0.4 } : { opacity: 0 }),
    });

    useScrollLock(open);
    return (
        <>
            <></>
            <Overlay open={open} {...overlayAttrs} style={{ ...overlayProps, ...overlayAttrs?.style }} />
            <Container open={open} style={props} {...rest}>
                {children}
            </Container>
        </>
    );
};

export default Popover;

export const StyledOverlay = styled.div<{ open: boolean }>`
    z-index: 9998;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    pointer-events: ${({ open }) => (open ? 'unset' : 'none')};
`;

const Overlay = animated(StyledOverlay);

const StyledContainer = styled.div<{ open: boolean }>`
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    input,
    textarea {
        ::placeholder {
            color: white;
            opacity: 0.6;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        top: 10vh;
        left: 10vw;
        right: 10vw;
        bottom: 10vh;
    }

    max-width: 1400px;
    margin: auto;
    pointer-events: ${({ open }) => (open ? 'unset' : 'none')};
    background: ${({ theme }) => theme.colors.primary[5]};
    overflow: auto;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        > div {
            max-width: 960px;
        }
    }
`;
const Container = animated(StyledContainer);

const PopoverCloseButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = props => (
    <StyledCloseButton {...props}>
        <Close />
    </StyledCloseButton>
);

export { PopoverCloseButton };

const StyledCloseButton = styled.button`
    border: none;
    position: absolute;

    right: 1.6rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        right: 2rem;
    }
    top: 2rem;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    z-index: 100;
    margin: 0;
    padding: 0;
    transition: all 200ms;
    outline: none;
    --border-color: transparant;
    ${createBorderCss('2px')};
    :active {
        transform: scale(1.2);
    }
    :focus {
        --border-color: ${({ theme }) => theme.colors.neutral[8]};
    }
    :hover {
        transform: rotate(90deg) scale(1.1);
    }
`;
