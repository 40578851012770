import React, { FC, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

const Burger: FC<ButtonHTMLAttributes<HTMLButtonElement> & { open: boolean }> = props => {
    return (
        <Container className="lt-s" {...props}>
            <div />
            <div />
            <div />
        </Container>
    );
};

export default Burger;

const Container = styled.button<{ open?: boolean }>`
    position: fixed;
    top: 1.6rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 9997;
    color: currentColor;

    &:focus {
        outline: none;
    }

    div {
        width: 2rem;
        height: 0.25rem;
        background: currentColor;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        width: 2rem;
        height: 0.25rem;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`;
