/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import 'normalize.css';
import React, { ComponentProps, ReactNode } from 'react';
import { useContactForm } from '../context/contactform';
import '../css/layout.css';
import GlobalStyles from '../globalStyles';
import ContactForm from './ContactForm';
import Footer from './Footer';
import Navigation from './Navigation';
import StepForm from './StepForm';
import { useStepForm } from '../context/stepform';
import Chat from './atoms/Chat';
import styled from 'styled-components';
import Appear from './Appear';

interface LayoutProps extends ComponentProps<'main'> {
    children: ReactNode;
    whiteBackground?: boolean;
}

const Layout = ({ children, whiteBackground = false, ...props }: LayoutProps) => {
    const [contactFormOpen, { onClose: onContactFormClose, onOpen: onContactFormOpen }] = useContactForm();
    const [{ open }, { onClose: onStepFormClose }] = useStepForm();

    return (
        <>
            <GlobalStyles />
            <Navigation whiteBackground={whiteBackground} />
            <main style={{ position: 'relative', marginTop: 'var(--nav-height)' }} {...props}>
                {children}
            </main>
            <Footer />
            <ContactForm open={contactFormOpen} onClose={onContactFormClose} />
            <StepForm open={!!open} onClose={onStepFormClose} />
            <QuestionWrapper>
                <Question
                    onClick={onContactFormOpen}
                    config="wobbly"
                    from={{ transform: 'scale(0)', opacity: 0 }}
                    to={{ transform: 'scale(1)', opacity: 1 }}
                >
                    <Chat />
                </Question>

                <Tooltip>Wij zijn online!</Tooltip>
            </QuestionWrapper>
        </>
    );
};

export default Layout;

const QuestionWrapper = styled.div`
    position: fixed;

    bottom: 0.8rem;
    right: 0.8rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        bottom: 1.6rem;
        right: 1.6rem;
    }

    display: flex;
    z-index: 100;
    flex-direction: row-reverse;
    align-items: center;
    > * + * {
        margin-right: 0.8rem;
    }
`;

const Question = styled(Appear)`
    padding: 0;
    outline: 0;
    cursor: pointer;
    transition: all 200ms;
    :hover {
        transform: scale(1.1);
    }
    border: none;

    width: 3.2rem;
    height: 3.2rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: 5rem;
        height: 5rem;
    }

    background: #db6588;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 19px 0 rgba(0, 0, 0, 0.3);
    position: relative;
    ::after {
        content: '';
        right: 0;
        width: 1.2rem;
        height: 1.2rem;
        background: ${({ theme }) => theme.colors.primary['6']};
        position: absolute;
        top: 5px;
        right: 2px;
        border-radius: 100%;
    }

    :hover {
        ~ * {
            opacity: 1;
        }
    }

    > div {
        position: absolute;
        left: -2rem;
        background: ${({ theme }) => theme.colors.primary[0]};
        opacity: 0.2;
    }

    > svg {
        width: 2rem;
    }
`;

const Tooltip = styled.div`
    color: white;
    background: rgba(0, 0, 0, 0.6);
    padding: 0.4rem 0.8rem;
    border-radius: 1.6rem;
    opacity: 0;
`;
