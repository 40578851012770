import styled from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

const Divider = styled.hr<SpaceProps & LayoutProps>`
    width: 100%;
    height: 1px;
    border: none;
    background-color: ${({ theme }) => theme.colors.neutral[8]};
    opacity: 0.2;
    ${space}
    ${layout}
`;

export default Divider;
