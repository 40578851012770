import React, { ButtonHTMLAttributes, forwardRef, Ref } from 'react';
import styled, { css } from 'styled-components/macro';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { getFontCss } from '../../constants/theme';
import createBorderCss from '../../utils/createBorderCss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'light' | 'dark' | 'outline' | 'transparant' | 'primary';
}

const Button = (props: ButtonProps & SpaceProps & LayoutProps & FlexboxProps, ref: Ref<HTMLButtonElement>) => {
    return <StyledButton type="button" {...props} ref={ref} />;
};

export default forwardRef(Button);

const StyledButton = styled.button<{ variant?: ButtonProps['variant'] }>`
    ${createBorderCss('2px')};
    --border-color: transparant;

    ${({ theme }) => getFontCss(theme.fontSizes.body.huge)};
    ${({ variant = 'dark' }) => {
        switch (variant) {
            case 'primary':
                return css`
                    background: ${({ theme }) => theme.colors.primary[5]};
                    color: white;

                    :hover,
                    :focus,
                    :active {
                        background: ${({ theme }) => theme.colors.primary[4]};
                    }
                `;
            case 'dark':
                return css`
                    background: ${({ theme }) => theme.colors.primary[1]};
                    color: white;
                `;
            case 'light':
                return css`
                    background: white;
                    color: ${({ theme }) => theme.colors.primary[4]};
                `;
            case 'outline':
                return css`
                    background: white;
                    color: ${({ theme }) => theme.colors.primary[4]};
                    --border-color: currentColor;
                    :hover,
                    :active,
                    :focus {
                        --border-color: ${({ theme }) => theme.colors.primary[4]};
                        color: white;
                        background: ${({ theme }) => theme.colors.primary[4]};
                    }
                `;
            case 'transparant':
                return css`
                    color: white;
                    background: unset;
                    --border-color: currentColor;
                    :hover,
                    :active,
                    :focus {
                        background: white;
                        color: ${({ theme }) => theme.colors.primary[4]};
                    }
                `;
            default:
                return '';
        }
    }};
    cursor: pointer;
    transition: all 100ms ease-in-out;
    height: 3.2rem;
    padding: 0 1.6rem;
    border-radius: 1.6rem;
    border: none;
    outline: none;
    font: inherit;
    &&{
        ${space}
        ${layout}
        ${flexbox}
    }


    :disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
`;
