import { createGlobalStyle, css } from 'styled-components';
import theme, { mediaQueriesValues, Theme } from './constants/theme';

export default createGlobalStyle`
${css`
    html {
        --min-viewport: 320;
        --max-viewport: ${mediaQueriesValues.xl};
    }
    body {
        background: ${theme.colors.neutral[8]};
        color: ${theme.colors.neutral[1]};
        font-family: Gotham;

        --nav-height: 7.2rem;
    }

    @media screen and (max-width: ${mediaQueriesValues.s - 1}px) {
        body {
            --nav-height: 5rem;
        }
    }
    /* section + section {
        margin-top: 3.2rem;
    } */

    @media screen and (min-width: ${theme.mediaQueries.s}) {
        section:not(:first-of-type) {
            padding-top: 7.2rem;
        }
    }

    a {
        color: ${theme.colors.primary[4]};
    }

    ${Object.keys(mediaQueriesValues).map(
        res => `
                .lt-${res} {
                    @media screen and (min-width: ${mediaQueriesValues[res as keyof Theme['mediaQueriesValues']]}px) {
                        display: none !important;
                    }
                }
                .gt-${res} {
                    @media screen and (max-width: ${mediaQueriesValues[res as keyof Theme['mediaQueriesValues']] -
                        1}px) {
                        display: none !important;
                    }
                }
            `
    )}
`}

`;
