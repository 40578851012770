/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-await-in-loop */
// import Compressor from 'compressorjs';
import { Formik, setNestedObjectValues, useFormikContext } from 'formik';
import JSZip from 'jszip';
import React, { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components/macro';
import * as Yup from 'yup';
import { useStepFormState } from '../context/stepform';
import { Sesame } from '../hooks/useSesame';
import createBorderCss from '../utils/createBorderCss';
import Appear from './Appear';
import Body from './atoms/Body';
import Box from './atoms/Box';
import Button from './atoms/Button';
import Close from './atoms/Close';
import ContentWrapper from './atoms/ContentWrapper';
import Flex from './atoms/Flex';
import Info from './atoms/Info';
import Input from './atoms/Input';
import Stack from './atoms/Stack';
import Title from './atoms/Title';
import Popover, { PopoverCloseButton } from './Popover';
import RoundButton from './RoundButton';

type Step = 0 | 1 | 2;
export interface StepState {
    active: Step;
    completed: Step[];
}
const zipRegEx = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

export const detailsValidation = Yup.object().shape({
    name: Yup.string().required('Vul een naam in'),
    email: Yup.string()
        .email('Vul een geldig email in')
        .required('Vul een email in'),
    number: Yup.number().required('Vul een huisnummer in'),
    address: Yup.string().required('Vul een adres in'),
    zipcode: Yup.string()
        .required('Vul een postcode in.')
        .matches(zipRegEx, 'Geen geldige postcode'),
    phone: Yup.number().required('Vul een telefoonnummer in'),
    place: Yup.string().required('Vul een plaats in'),
});

export const StepStateContext = createContext<StepState>({ active: 0, completed: [] });
const StepActionContext = createContext<((step: StepState) => void) | undefined>(undefined);
export const StepContext: FC<{ stepState: StepState; dispatch: (step: StepState) => void }> = ({
    children,
    stepState,
    dispatch,
}) => {
    return (
        <StepStateContext.Provider value={stepState}>
            <StepActionContext.Provider value={dispatch}>{children}</StepActionContext.Provider>
        </StepStateContext.Provider>
    );
};

const useStep = () => {
    const step = useContext(StepStateContext);
    const stepAction = useContext(StepActionContext);
    if (!stepAction) {
        throw new Error('Use the provider bitch');
    }
    return [step, stepAction] as const;
};

// type BlobImage = Blob & { preview: string; name: string };
type Files = { file: File; preview: string }[];
interface Values {
    name: string;
    email: string;
    number: string;
    addition: string;
    address: string;
    zipcode: string;
    phone: string;
    place: string;
    price: number | string;
    files: Files;
}

const StepForm: FC<Pick<Sesame, 'onClose' | 'open'>> = ({ onClose, open }) => {
    const { initialValues } = useStepFormState();
    const [state, setState] = useState<StepState>({ active: 0, completed: [] });

    useEffect(() => {
        if (initialValues) {
            if (state.completed.length === 0) {
                setState({ active: 1, completed: [0] });
            }
        }
    }, [initialValues, state.completed.length]);

    const isCompleted = new Set(state.completed).size === 3;

    return (
        <Popover open={open}>
            <StepContext stepState={state} dispatch={setState}>
                <Formik<Values>
                    enableReinitialize
                    initialTouched={initialValues ? setNestedObjectValues(initialValues, true) : undefined}
                    initialValues={{
                        name: '',
                        email: '',
                        address: '',
                        number: '',
                        addition: '',
                        zipcode: '',
                        phone: '',
                        place: '',
                        price: '',
                        files: [],
                        ...initialValues,
                    }}
                    onSubmit={async ({ files, ...values }, { setSubmitting, setFieldError }) => {
                        const zip = new JSZip();
                        const formData = new FormData();
                        formData.append('form-name', 'bodaanvraag');

                        Object.entries(values).forEach(([key, value]) => {
                            formData.append(key, value);
                        });

                        files.forEach(file => zip.file(file.file.name, file.file));

                        const zippedFile =
                            files.length > 0
                                ? await zip.generateAsync({ type: 'uint8array' }).then(blob => {
                                      const file = new File([blob], 'images.zip', {
                                          lastModified: Date.now(),
                                          type: 'application/zip',
                                      });
                                      return file;
                                  })
                                : undefined;

                        try {
                            if (zippedFile) {
                                formData.append('files', zippedFile);
                            }
                            await fetch('/?no-cache=1', {
                                method: 'POST',
                                body: formData,
                            });
                        } catch (error) {
                            // eslint-disable-next-line no-console
                            console.error(error);
                            setFieldError('name', 'Er is iets misgegaan. Probeer het later opnieuw.');
                        }

                        setSubmitting(false);

                        setState(old => ({ ...old, completed: old.completed.concat(2) }));
                    }}
                    validationSchema={detailsValidation}
                    validateOnMount
                >
                    {({ resetForm, dirty, validateForm, handleSubmit, handleReset }) => {
                        return (
                            <ContentWrapper flexDirection="column" display="flex">
                                <PopoverCloseButton
                                    onClick={() => {
                                        if (
                                            (dirty || !isCompleted) &&
                                            window.confirm(
                                                'Weet u zeker dat u het formulier wilt sluiten? Uw ingevulde gegevens gaan verloren.'
                                            )
                                        ) {
                                            onClose();
                                            setTimeout(() => {
                                                resetForm();
                                                validateForm();
                                                setState({ active: 0, completed: [] });
                                            }, 200);
                                        } else if (isCompleted) {
                                            setTimeout(() => {
                                                onClose();
                                                resetForm();
                                                setState({ active: 0, completed: [] });
                                            }, 200);
                                        } else {
                                            onClose();
                                        }
                                    }}
                                />
                                <Flex
                                    justifyContent={['flex-end']}
                                    alignItems={[null]}
                                    flexDirection={['column-reverse']}
                                    pt={['5.8rem']}
                                    my="auto"
                                >
                                    {isCompleted ? (
                                        <Box style={{ color: 'white' }}>
                                            <Appear>
                                                <Title variant="large">Bedankt voor uw aanvraag</Title>
                                            </Appear>
                                            <Appear delay={250}>
                                                <Body>Wij nemen zo spoedig mogelijk contact met u op.</Body>
                                            </Appear>
                                        </Box>
                                    ) : (
                                        <>
                                            <Flex flexDirection="column" flex={[null, null]}>
                                                <form
                                                    name="bodaanvraag"
                                                    onSubmit={handleSubmit}
                                                    onReset={handleReset}
                                                    data-netlify="true"
                                                    data-netlify-honeypot="bot-field"
                                                >
                                                    <input type="hidden" name="form-name" value="bodaanvraag" />
                                                    {steps.map((Component, i) => (
                                                        <Component key={i} />
                                                    ))}
                                                </form>
                                            </Flex>
                                            <Steps />
                                        </>
                                    )}
                                </Flex>
                            </ContentWrapper>
                        );
                    }}
                </Formik>
            </StepContext>
        </Popover>
    );
};

export default StepForm;

const useStepValidity = (fields: Array<keyof Values>): boolean => {
    const { errors } = useFormikContext<Values>();
    return fields.some(field => errors[field]);
};

export const StepOne = ({ onSubmit }: { onSubmit?: () => void }) => {
    const [step, setStep] = useStep();
    const disabled = useStepValidity(['name', 'phone', 'email']);

    return (
        <Wrapper spacing={4} display={step.active !== 0 ? 'none' : 'flex'}>
            <Appear delay={250} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                <Title style={{ color: 'white' }} variant="large" elementType="h2">
                    Bod aanvragen?
                </Title>
            </Appear>
            <Appear delay={500} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                <Body variant="large">Vraag in 3 stappen een vrijblijvend bod aan!</Body>
            </Appear>
            <Appear delay={750} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                <Stack spacing={5} alignItems="flex-start">
                    <Input name="name" placeholder="Naam" />
                    <Input name="phone" placeholder="Telefoonnummer" />
                    <Input type="email" name="email" placeholder="Email" />
                </Stack>
            </Appear>
            <ErrorMessage />
            <Appear delay={1000} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                <Button
                    disabled={disabled}
                    style={{ marginTop: '2.4rem' }}
                    onClick={() => {
                        if (onSubmit) {
                            onSubmit();
                        } else {
                            setStep({ active: 1, completed: [0] });
                        }
                    }}
                >
                    Verder naar stap 2
                </Button>
            </Appear>
        </Wrapper>
    );
};

const ErrorMessage = () => {
    const { errors, touched } = useFormikContext<Values>();

    const error = Object.entries(errors).find(([key]) => touched[key as keyof typeof touched])?.[1];
    if (!error) {
        return null;
    }
    return <Body style={{ marginTop: '3.2rem' }}>{error}</Body>;
};

const StepTwo = () => {
    const [state, setStep] = useStep();
    const { errors, values, getFieldProps, setFieldError, setValues } = useFormikContext<Values>();
    const doFetch = !errors.zipcode && !errors.number;
    const called = useRef('');
    const disabled = useStepValidity(['zipcode', 'place', 'address', 'number']);

    const fetchAddress = useCallback(
        async (zipcode: string, houseNumber: string) => {
            if (called.current === zipcode.concat(houseNumber)) {
                return;
            }
            try {
                const response = await fetch(
                    `https://geodata.nationaalgeoregister.nl/locatieserver/free?fq=postcode:${zipcode.replace(
                        ' ',
                        ''
                    )}&fq=huisnummer:${houseNumber}`
                );
                const data = await response.json();

                const result = data.response.docs[0];

                if (!result) {
                    setFieldError('zipcode', 'Er is geen adres gevonden bij deze postcode');
                    return;
                }

                setValues({ ...values, address: result.straatnaam, place: result.woonplaatsnaam });
                called.current = zipcode.concat(houseNumber);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
                setFieldError('zipcode', 'Er is geen adres gevonden bij deze postcode');
            }
        },
        [setFieldError, setValues, values]
    );

    const onBlurZipcode = useCallback(
        (e: React.FocusEvent) => {
            getFieldProps('zipcode').onBlur(e);
            if (doFetch) {
                fetchAddress(values.zipcode, values.number);
            }
        },
        [doFetch, fetchAddress, getFieldProps, values.number, values.zipcode]
    );

    const onBlurNumber = useCallback(
        (e: React.FocusEvent) => {
            getFieldProps('number').onBlur(e);
            if (doFetch) {
                fetchAddress(values.zipcode, values.number);
            }
        },
        [doFetch, fetchAddress, getFieldProps, values.number, values.zipcode]
    );

    return (
        <Wrapper spacing={4} display={state.active !== 1 ? 'none' : 'flex'}>
            <Appear delay={250} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                <Title style={{ color: 'white' }} variant="large" elementType="h2">
                    Adres gegevens
                </Title>
            </Appear>
            <Appear delay={500} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                <Stack spacing={5} alignItems="flex-start">
                    <Input name="zipcode" placeholder="Postcode" onBlur={onBlurZipcode} />
                    <Stack spacing={5} variant="inline">
                        <Input
                            name="number"
                            type="number"
                            placeholder="Huisnummer"
                            width="20rem"
                            onBlur={onBlurNumber}
                        />
                        <Input name="addition" placeholder="Toevoeging" width="16rem" />
                    </Stack>
                    <Input disabled name="address" placeholder="Straat" />

                    <Input disabled name="place" placeholder="Plaats" />
                </Stack>
            </Appear>
            <ErrorMessage />
            <Appear delay={750} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                <Button
                    disabled={disabled}
                    style={{ marginTop: '2.4rem' }}
                    onClick={() => setStep({ active: 2, completed: state.completed.concat(1) })}
                >
                    Verder naar stap 3
                </Button>
            </Appear>
        </Wrapper>
    );
};

// const compressFile = (file: File): Promise<Blob> =>
//     new Promise((resolve, reject) => {
//         // eslint-disable-next-line no-new
//         new Compressor(file, {
//             quality: 50,
//             success: resolve,
//             error: reject,
//         });
//     });
const StepThree = () => {
    const { submitForm, setFieldValue, values } = useFormikContext<{ files: Files }>();
    const [step] = useStep();
    const onDrop = useCallback(
        acceptedFiles => {
            setFieldValue('files', [
                ...values.files,
                ...acceptedFiles.map((file: File) => ({ file, preview: URL.createObjectURL(file) })),
            ]);
        },
        [setFieldValue, values.files]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png',
        multiple: false,
    });
    return (
        <Wrapper spacing={4} pt={[null, null, 5]} display={step.active !== 2 ? 'none' : 'flex'}>
            <Appear delay={250} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                <Title style={{ color: 'white' }} variant="large" elementType="h2">
                    Vraagprijs indicatie
                </Title>
                <Body variant="huge">Welk bedrag zou u minimaal willen ontvangen voor uw woning (€)?</Body>
            </Appear>
            <Appear delay={500} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                <Stack spacing={5}>
                    <Input isCurrency maxWidth="10rem" name="price" placeholder="Prijs" />
                    <Box>
                        <Flex alignItems="center" mb={4}>
                            <Body variant="huge">Foto's van de woning (optioneel)</Body>
                            <Info
                                data-tip="Foto’s geven ons een beter beeld van de staat van de woning en zorgt ervoor dat we een realistisch bod kunnen uitbrengen."
                                style={{ marginLeft: '0.8rem' }}
                                width={30}
                                height={30}
                            />
                        </Flex>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} name="files" />
                            <Dropzone>
                                <ImgWrapper>
                                    {values.files.map((img, i) => (
                                        <Img key={i}>
                                            <StyledRoundButton
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    const currFile = values.files[i];
                                                    setFieldValue(
                                                        'files',
                                                        values.files.filter((file, fileIndex) => i !== fileIndex)
                                                    );
                                                    URL.revokeObjectURL(currFile.preview);
                                                }}
                                            >
                                                <Close />
                                            </StyledRoundButton>
                                            <img src={img.preview} alt={img.file.name} />
                                        </Img>
                                    ))}
                                </ImgWrapper>
                                {values.files.length === 0 &&
                                    (isDragActive
                                        ? 'Laat de bestanden los'
                                        : `Indien u wilt, kunt u hier foto's uploaden`)}
                            </Dropzone>
                        </div>
                    </Box>
                    <Appear delay={600} from={{ opacity: 0, transform: 'translateY(10vh)' }}>
                        <Input height="2rem" as="textarea" name="comment" placeholder="Opmerkingen" />
                    </Appear>
                </Stack>
            </Appear>
            <Button style={{ marginTop: '2.4rem', alignSelf: 'flex-start' }} onClick={submitForm}>
                Versturen
            </Button>
            <StyledReactTooltip effect="solid" />
        </Wrapper>
    );
};
interface StepProps {
    step: number;
    visited?: boolean;
    onClick: () => void;
    inline?: boolean;
}
const steps = [StepOne, StepTwo, StepThree] as const;

const StyledReactTooltip = styled(ReactTooltip)`
    max-width: 15rem;
    font: inherit;
`;

const Step = ({ step, visited: active, onClick, inline }: StepProps) => {
    return (
        <StepContainer completed={active} inline={inline}>
            <Title variant="huge" elementType="button" onClick={onClick}>
                {step}
            </Title>
        </StepContainer>
    );
};

export const Steps = ({ inline = false }: { inline?: boolean }) => {
    const [state, setState] = useStep();

    return (
        <StepsContainer inline={inline} completed={state.completed}>
            {new Array(3).fill(null).map((_, i) => {
                const isActive = state.active === i;
                const isVisited = state.completed.includes(i as Step);
                return (
                    <Step
                        inline={inline}
                        key={i}
                        step={i + 1}
                        visited={isVisited || isActive}
                        onClick={() => {
                            if (!isVisited) {
                                return;
                            }
                            setState({
                                ...state,
                                active: i as Step,
                                completed: state.completed.filter(step => step !== 2),
                            });
                        }}
                    />
                );
            })}
        </StepsContainer>
    );
};

const StyledRoundButton = styled(RoundButton)`
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: -0.8rem;
    right: -0.8rem;
`;

const Img = styled.div`
    position: relative;
    /* max-height: 10rem; */
`;

const ImgWrapper = styled.div`
    display: grid;
    column-gap: 1.6rem;
    row-gap: 1.6rem;

    grid-template-columns: 1fr 1fr 1fr;
    > div {
        width: 100%;
    }
    img {
        object-fit: cover;
        margin-bottom: 0;
        border-radius: 0.4rem;
        box-shadow: 0 10px 19px 0 rgba(0, 0, 0, 0.3);
        max-height: 16rem;
        width: 100%;
    }
`;

const Dropzone = styled.div`
    width: auto;
    min-height: 3.2rem;
    border: 2px dashed white;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.8rem;
`;

const StepsContainer = styled.div<{ inline?: boolean; completed: Step[] }>`
    display: flex;
    justify-content: space-between;
    position: relative;
    ::after {
        pointer-events: none;
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        height: 2px;
        width: 50%;
        background: white;
        opacity: ${({ completed }) => (completed.includes(0) ? 1 : 0.4)};
    }
    ::before {
        opacity: ${({ completed }) => (completed.includes(1) ? 1 : 0.4)};
        pointer-events: none;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        height: 2px;

        width: 50%;
        background: white;
    }
    ${({ inline }) =>
        !inline &&
        css`
            margin-bottom: 2.4rem;
        `};
`;

const smallCss = css<{ completed?: boolean }>`
    padding: 0;
    display: flex;
    justify-content: center;

    background: ${({ completed, theme }) => (completed ? 'unset' : theme.colors.primary[5])};
    z-index: 1;
    button {
        line-height: 0;
        padding: none;
        padding-top: 3px;
        --border-color: white;
        ${createBorderCss('2px')};
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        font-size: 20px;
        position: relative;
        opacity: ${({ completed }) => (completed ? 1 : 0.4)};
        background: ${({ completed, theme }) => (completed ? 'white' : theme.colors.primary[5])};
        color: ${({ completed, theme }) => (completed ? theme.colors.primary[5] : 'white')};
    }
`;

const StepContainer = styled.div<{ completed?: boolean; inline?: boolean }>`
    transition: all 250ms;
    position: relative;
    button {
        z-index: 1;
        margin: 0;
        padding: 0 1rem;
        background: unset;
        border: none;
        height: auto;
        color: white;
        position: relative;
        /* ::after {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            width: 2px;
            background: white;
        } */
    }
    ${smallCss};
`;

export const Wrapper = styled(Stack)`
    color: white;
    padding-bottom: 3.2rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        margin-top: 3.2rem;
        padding-right: 3.2rem;
        padding-left: 3.2rem;
        padding-bottom: 8rem;
    }
`;
