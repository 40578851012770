import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import theme from '../constants/theme';

const RoundButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = props => {
    return <Button type="button" {...props} />;
};

export default RoundButton;

const Button = styled.button`
    width: 5rem;
    height: 5rem;
    padding: 0;
    border: none;
    border-radius: 50%;
    background: ${theme.colors.neutral[8]};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms all;
    opacity: 0.7;
    cursor: pointer;
    :hover {
        opacity: 1;
        transform: scale(1.1);
    }
    :focus {
        filter: brightness(0.9);
    }

    outline: none;
`;
