import { GatsbyLinkProps, Link as NavLink, navigate } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { FC, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { getFontCss } from '../constants/theme';
import { useContactForm } from '../context/contactform';
import { useDeviceSize } from '../context/devicesize';
import { useStepForm } from '../context/stepform';
import useSesame from '../hooks/useSesame';
import Box from './atoms/Box';
import Burger from './atoms/Burger';
import Button from './atoms/Button';
import ContentWrapper from './atoms/ContentWrapper';
import Logo from './atoms/Logo';
import Stack from './atoms/Stack';
import Title, { TitleInner } from './atoms/Title';
import Popover from './Popover';
import triggerGAEvent from '../utils/triggerGAEvent';

const Navigation: FC<{ whiteBackground?: boolean }> = props => {
    const [whiteBackground, setWhiteBackground] = useState(props.whiteBackground);
    const theme = useTheme();
    const { open, onToggle, onClose } = useSesame();
    const [contactFormOpen] = useContactForm();

    const [{ open: stepFormOpen }, { onOpen: onOpenStepForm }] = useStepForm();

    const deviceSize = useDeviceSize();

    useEffect(() => {
        if (open && deviceSize !== 'mobile') {
            onClose();
        }
    }, [deviceSize, onClose, open]);
    useEffect(() => {
        const scrollHandler = () => {
            if (props.whiteBackground || contactFormOpen || stepFormOpen) {
                return;
            }
            if (window.pageYOffset > 100) {
                setWhiteBackground(true);
            } else {
                setWhiteBackground(false);
            }
        };

        window.addEventListener('scroll', scrollHandler);

        return () => window.removeEventListener('scroll', scrollHandler);
    }, [contactFormOpen, props.whiteBackground, stepFormOpen]);

    return (
        <>
            <nav
                style={{
                    background: whiteBackground ? 'white' : undefined,
                    transition: 'all 0.3s',
                    zIndex: 9000,
                    boxShadow: whiteBackground
                        ? '0 1.6rem 2.4rem 0 rgba(59, 118, 160, 0.03), 0 2.4rem 8rem 0 rgba(59, 118, 160, 0.05)'
                        : undefined,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <StyledWrapper invert={whiteBackground}>
                    <Logo onClick={() => navigate('/')} />

                    <Link className="gt-s" to="/">
                        Home
                    </Link>
                    <Link className="gt-s" to="/#hoe-werkt-het" isAnchor>
                        Hoe werkt het?
                    </Link>
                    <Link className="gt-s" to="/over-ons">
                        Over ons
                    </Link>
                    <Link className="gt-s" to="/contact">
                        Contact
                    </Link>
                    <Button
                        px={[4, null, null, 5]}
                        className="gt-s"
                        variant={whiteBackground ? 'outline' : 'transparant'}
                        onClick={() => onOpenStepForm()}
                        type="button"
                    >
                        Start verkoop
                    </Button>
                </StyledWrapper>
            </nav>
            <Burger
                onClick={onToggle}
                open={open}
                style={{ color: whiteBackground || open ? theme.colors.primary[3] : 'white' }}
            />

            <StyledPopover open={open} variant="slide" className="lt-s" overlayAttrs={{ style: { zIndex: 9995 } }}>
                <Logo
                    onClick={() => {
                        navigate('/');
                        onClose();
                    }}
                />
                <Menu spacing={4}>
                    <Box>
                        <Link to="/" onClick={onClose}>
                            Home
                        </Link>
                    </Box>

                    <Link to="/over-ons" onClick={onClose}>
                        Over ons
                    </Link>
                    <Link to="/#hoe-werkt-het" isAnchor>
                        Hoe werkt het?
                    </Link>
                    <Link to="/contact" isAnchor>
                        Contact
                    </Link>
                </Menu>

                <Box>
                    <Button
                        variant="outline"
                        onClick={() => {
                            triggerGAEvent('click', { event_category: 'Formulier', event_label: 'Start verkoop' });
                            onOpenStepForm();
                            onClose();
                        }}
                    >
                        Start verkoop
                    </Button>
                </Box>
                {/* <Menu>
                    <Burger onClick={onToggle} open={open} />
                </Menu> */}
            </StyledPopover>
        </>
    );
};

const StyledPopover = styled(Popover)`
    background: white;
    padding-left: 1.6rem;
    padding-bottom: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 1.2rem;
    color: ${({ theme }) => theme.colors.primary[3]};
    display: flex;
    z-index: 9996;
    flex-direction: column;
    justify-content: space-between;
`;

export default Navigation;

export const Link: FC<GatsbyLinkProps<{}> & { isAnchor?: boolean }> = ({ children, isAnchor, ...props }) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    <Anchor {...props} activeClassName="active" as={isAnchor && AnchorLink}>
        <div>
            <Title mb={0} variant="small">
                {children}
            </Title>
        </div>
    </Anchor>
);

const Anchor = styled(NavLink)`
    text-decoration: none;
    color: currentColor;
    ${TitleInner} {
        font-weight: 500 !important;
    }
    transition: all 200ms;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        :hover,
        :active {
            transform: scale(1.1);
            > * {
                ::after {
                    opacity: 1;
                    transform: translateX(0);
                    color: ${({ theme }) => theme.colors.primary[3]};
                }
            }

            h2 {
                color: ${({ theme }) => theme.colors.primary[3]};
            }
        }
    }

    position: relative;
    > * {
        padding-bottom: 2px;
        transition: all 200ms;
        position: relative;
        overflow: hidden;
        ::after {
            transition: all 200ms;
            position: absolute;
            content: '';
            width: 100%;
            bottom: 0;
            height: 2px;
            background: currentColor;
            opacity: 0;
            transform: translateX(-100%);
        }
    }
`;

const Menu = styled(Stack)`
    ${TitleInner} {
        ${({ theme }) => getFontCss(theme.fontSizes.title.large)}
    }
`;

const StyledWrapper = styled(ContentWrapper)<{ invert?: boolean }>`
    height: var(--nav-height);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${({ invert, theme }) => (invert ? theme.colors.primary[3] : 'white')};

    > * + * {
        margin-left: 1.6rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        > * + * {
            margin-left: 3.2rem;
        }
    }

    .logo {
        margin-right: auto;
    }
    .active {
        > div {
            ::after {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
`;
